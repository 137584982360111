import React from 'react';
import Hero from './Hero';
import Footer from '../common/Footer';

const Home = (props) => {
    return (
        <div style={styles.container}>
            <Hero />
            <Footer />
        </div>
    );
}

const styles = {
    height: '100%',
    width: '100%',
};

export default Home;