const Footer = () => {
    return (
        <div style={styles.container}>
            <p style={styles.content}>&#169; by Nexter LLC. Coming soon</p>
        </div>
    );
}

const styles = {
    container: {
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#000',
        width: '100%',
        height: 140,
        textAlign: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
    },
    content: {
        color: '#fff',
        fontSize: 18,
        fontFamily: 'Lato',
    }
}

export default Footer;