import background from '../../assets/hero-img.jpg';

const Hero = (props) => {
    return (
        <div style={styles.container}>
            <div style={styles.imgView}>
                <img src={background} style={styles.img} alt="Foggy view of Los Angeles skyline" />
            </div>
            <div style={styles.overlay} />
            <div style={{ paddingTop: 300, textAlign: 'center', position: 'relative'}}>
                <p style={styles.header}>UNDER</p>
                <p style={styles.header}>CONSTRUCTION</p>
            </div>
        </div>
    );
}

const styles = {
    container: {
        position: 'relative',
        width: '100%',
        height: 1650,
        zIndex: 0,
        display: 'flex',
        justifyContent: 'center',
    },
    imgView: {
        position: 'absolute',
        height: '100%',
        width: '100%',
    },
    img: {
        objectFit: 'cover',
        height: '100%',
        width: '100%',
    },
    overlay: {
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: 'rgba(0, 0, 0, 0.6)'
    },
    header: {
        color: '#fff',
        fontSize: 62,
        fontFamily: 'Lato',
        fontWeight: '400',
        lineHeight: 0.4,
    }
}

export default Hero;